import $wxsdk from '@/sheep/libs/sdk-h5-weixin';
import { getRootUrl } from '@/sheep/helper';
import AuthUtil from '@/sheep/api/member/auth';
import SocialApi from '@/sheep/api/member/social';
import { storageEnum } from '@/enum/storageEnum';

const socialType = 31; // 社交类型 - 微信公众号


// 加载微信公众号JSSDK
async function load() {
    $wxsdk.init();
}

// 微信公众号登陆
async function login(code = '', state = '') {
  // 情况一：没有 code 时，去获取 code
  if (!code) {
    console.log('没有 code，去获取 code');
    const url = await getLoginUrl();
    const page = getRootUrl() + 'pages/author/index'+'?event=login&state=' + getQueryString(url,'state'); 
    const loginUrl = `https://weauth-proxy.fushangyunfu.com/?redirect_url=${encodeURIComponent(page)}`
 
    if (loginUrl) {
      uni.setStorageSync('returnUrl', location.href);
      window.location = loginUrl;
      console.log('跳转到微信公众号授权页面,loginUrl:'+loginUrl);
    }
  } 
 
  
  // // 情况二：有 code 时，使用 code 去自动登录
  // else {
  //   // 解密 code 发起登陆
  //   if(code !== '') socialCode = code;
  //   if(state !== '') socialState = state;
  //   const loginResult = await AuthUtil.socialLogin(socialType, code, state);
  //   if (loginResult.code === 0) {
  //     // TODO 芋艿：shareLog
  //     setOpenid(loginResult.data.openid);
  //     return loginResult;
  //   }
  // }
  return false;
}

//  获取链接中对应的参数

function getQueryString(url,name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = url.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";
  if (r != null)
      context = decodeURIComponent(r[2]);
  reg = null;
  r = null;
  return context == null || context == "" || context == "undefined" ? "" : context;

}

// 微信公众号绑定
async function bind(code = '', state = '') {
  // 情况一：没有 code 时，去获取 code
  if (code === '') {
    const loginUrl = await getLoginUrl('bind');
    if (loginUrl) {
      uni.setStorageSync('returnUrl', location.href);
      // window.location = loginUrl;
      window.location.href =loginUrl;
    }
  }
  else {
    // 情况二：有 code 时，使用 code 去自动绑定
    const loginResult = await SocialApi.socialBind(socialType, code, state);
    if (loginResult.code === 0) {
      setOpenid(loginResult.data);
      return loginResult;
    }
  }
  return false;
}

// 微信公众号解除绑定
const unbind = async (openid) => {
  const { code } = await SocialApi.socialUnbind(socialType, openid);
  return code === 0;
};

// 获取公众号登陆地址
async function getLoginUrl(event = 'login') {
  const page = getRootUrl() + 'pages/author/index'
    + '?event=' + event; // event 目的，区分是 login 还是 bind
  const { code, data } = await AuthUtil.socialAuthRedirect(socialType, page);
  //https://open.weixin.qq.com/connect/oauth2/authorize?
  //appid=wx3199309e049be290&response_type=code&scope=snsapi_userinfo&state=98bb0b297812cbcfb28ae51ca240301d&
  //redirect_uri=http://chenyang.v7.idcfengye.com/%23/pages/index/login?event=login&connect_redirect=1#wechat_redirect
  if (code !== 0) {
    return undefined;
  }
  return data;
}

// 设置 openid 到本地存储，目前只有 pay 支付时会使用
function setOpenid(openid) {
  uni.setStorageSync('openId', openid);
}

// 获得 openid
async function getOpenid(force = true) {
  let openid = uni.getStorageSync('openId');
  if (!openid && force) {
    const info = await getInfo();
    if (info && info.openid) {
      openid = info.openid;
      setOpenid(openid);
    }
  }
  return openid;
}

// 获得社交信息
async function getInfo() {
  const { code, data } = await SocialApi.getSocialUser(socialType);
  if (code !== 0) {
    return undefined;
  }
  return data;
}

export default {
  load,
  login,
  bind,
  unbind,
  getInfo,
  getOpenid,
  jssdk: $wxsdk,
};
