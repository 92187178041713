// 获取设备信息
// key      指定key
// dv       默认数据（不存在则读取、默认null）
// is_real  是否实时读取
import { commonEnum } from "@/enum/commonEnum";
import { projectConfig } from "@/sheep/projectConfig/projectConfig";
import SystemApi from '@/sheep/api/system/system'
import wx from 'weixin-js-sdk'

export const getSystemInfo = (key, dv, isReal) => {
  var info = null;
  if ((isReal || false) == true) {
    info = setSystemInfo() || null;
  } else {
    info = uni.getStorageSync(commonEnum.cacheSystemInfoKey) || null;
  }
  if (info == null || (key || null) == null) {
    return info;
  }
  return info[key] == undefined ? (dv == undefined ? null : dv) : info[key];
};

// 设置设备信息
const setSystemInfo = () => {
  var systemInfo = uni.getSystemInfoSync();
  uni.setStorageSync(commonEnum.cacheSystemInfoKey, systemInfo);
  return systemInfo;
};

// 颜色转rgba hexValue： 色值  alpha：透明度
export function hex_rgba(hexValue, alpha) {
  const rgx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexValue.replace(rgx, (m, r, g, b) => r + r + g + g + b + b);
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!rgb) {
    return hexValue;
  }
  const r = parseInt(rgb[1], 16),
    g = parseInt(rgb[2], 16),
    b = parseInt(rgb[3], 16);
  return `rgba(${r},${g},${b},${alpha})`;
}

// 是否站点变灰
export function isAppMourning() {
  var is_app = parseInt(get_config("plugins_base.mourning.data.is_app", 0));
  if (is_app == 1) {
    // 当前时间戳
    var time_current = Date.parse(new Date());
    // 开始时间
    var time_start =
      get_config("plugins_base.mourning.data.time_start") || null;
    if (time_start != null) {
      if (Date.parse(new Date(time_start)) > time_current) {
        return false;
      }
    }
    // 结束时间
    var time_end = get_config("plugins_base.mourning.data.time_end") || null;
    if (time_end != null) {
      if (Date.parse(new Date(time_end)) < time_current) {
        return false;
      }
    }
    return true;
  }
  return false;
}

/**
 * 获取配置信息、可指定key和默认值
 * key              数据key（支持多级读取、以 . 分割key名称）
 * default_value    默认值
 */
export function get_config(key, default_value) {
  var value = null;
  var config = uni.getStorageSync(commonEnum.cacheConfigInfoKey) || null;
  if (config != null) {
    // 数据读取
    var arr = key.split(".");
    if (arr.length == 1) {
      value = config[key] == undefined ? null : config[key];
    } else {
      value = config;
      for (var i in arr) {
        if (value[arr[i]] != undefined) {
          value = value[arr[i]];
        } else {
          value = null;
          break;
        }
      }
    }
  }
  // 默认值
  if (value == null && default_value != undefined) {
    value = default_value;
  }
  return value;
}

// 底部菜单设置
export function setTabbar(theme) {
  // 当前主题
  if ((theme || null) == null) {
    theme = uni.getStorageSync("theme") || "red";
  }

  // 整体样式
  uni.setTabBarStyle({
    selectedColor: uni.getStorageSync("theme") || "red",
  });

  // 菜单
  uni.setTabBarItem({
    index: 0,
    iconPath: "static/images/common/tabbar/home.png",
    selectedIconPath: "static/images/" + theme + "/tabbar/home.png",
    text: "首页",
  });
  uni.setTabBarItem({
    index: 1,
    iconPath: "static/images/common/tabbar/category.png",
    selectedIconPath: "static/images/" + theme + "/tabbar/category.png",
    text: "分类",
  });
  uni.setTabBarItem({
    index: 2,
    iconPath: "static/images/common/tabbar/cart.png",
    selectedIconPath: "static/images/" + theme + "/tabbar/cart.png",
    text: "购物车",
  });
  uni.setTabBarItem({
    index: 3,
    iconPath: "static/images/common/tabbar/user.png",
    selectedIconPath: "static/images/" + theme + "/tabbar/user.png",
    text: "我的",
  });
}

export function chooseUserLocationEvent() {
  uni.navigateTo({
    url: "/pages/common/open-setting-location/open-setting-location",
  });
}

// app标题
export function getApplicationTitle() {
  var value = null;
  // 根据终端类型获取对应数据
  var type = this.applicationClientType() || null;
  if (type !== null) {
    value = get_config("config.common_app_mini_" + type + "_title") || null;
  }
  // 获取公共数据
  if (value === null) {
    value = get_config("config.home_site_name", this.data.application_title);
  }
  return value;
}

// 终端类型值
function applicationClientType() {
  var value = "";
  // #ifdef MP-WEIXIN
  value = "weixin";
  // #endif
  // #ifdef MP-ALIPAY
  value = "alipay";
  // #endif
  // #ifdef MP-BAIDU
  value = "baidu";
  // #endif
  // #ifdef MP-QQ
  value = "qq";
  // #endif
  // #ifdef MP-TOUTIAO
  value = "toutiao";
  // #endif
  // #ifdef MP-KUAISHOU
  value = "kuaishou";
  // #endif
  // #ifdef H5
  value = "h5";
  // #endif
  // #ifdef APP
  value = getSystemInfo("platform", null, true);
  // #endif
  return value;
}

// applogo
export function getApplicationLogo() {
  var logo = projectConfig.applicationLogo || null;
  if (logo == null) {
    logo =
      get_config("config.home_site_logo_app") ||
      get_config("config.home_site_logo_wap");
  }
  return logo;
}

/**
 * 配置是否有效(100毫秒检验一次、最多检验100次)
 * object     回调操作对象
 * method     回调操作对象的函数
 */
export function isConfig(object, method) {
  var count = 0;
  var timer = setInterval(function () {
    if (get_config("status") == 1) {
      clearInterval(timer);
      if (typeof object === "object" && (method || null) != null) {
        object[method](true);
      }
    }
    count++;
    if (count >= 100) {
      clearInterval(timer);
    }
  }, 100);
}

export function getThemeValue() {
  let temp = uni.getStorageSync("theme") || "red";
  return "theme-" + temp;
}

// 数组分组
export function groupArr(arr, subGroupLength) {
  let index = 0;
  let newArr = [];
  if (arr.length > subGroupLength) {
    while (index < arr.length) {
      newArr.push(arr.slice(index, (index += subGroupLength)));
    }
  } else {
    newArr = [arr];
  }
  return newArr;
}

// 时间格式转换，精确到秒数
export function formatDate1(timestamp) {
  if (!timestamp) return "";
  let date = new Date(timestamp); // 使用 Date 类将时间戳转换为日期对象
  let year = date.getFullYear(); // 获取完整的年份，比如 2021
  let month = date.getMonth() + 1; // 获取月份，注意月份范围是 0-11，所以需要 +1
  if (month < 10) {
    month = "0" + month;
  }
  let day = date.getDate(); // 获取日期，比如 27
  if (day < 10) {
    day = "0" + day;
  }
  let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); // 获取小时，比如 9
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); // 获取分钟，比如 12
  let seconds =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // 获取秒数，比如 24
  // 拼接成标准时间格式的字符串
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}



// 时间格式转换，精确到日期
export function formatDate2(timestamp) {
  let date 
  if(!timestamp){
    date = new Date()
  } else{
    date = new Date(timestamp)
  }
  let year = date.getFullYear(); // 获取完整的年份，比如 2021
  let month = date.getMonth() + 1; // 获取月份，注意月份范围是 0-11，所以需要 +1
  if (month < 10) {
    month = "0" + month;
  }
  let day = date.getDate(); // 获取日期，比如 27
  if (day < 10) {
    day = "0" + day;
  }
  // 拼接成标准时间格式的字符串
  return `${year}-${month}-${day}`;
}

// 时间格式转换，精确到日期兼容ios
export function formatDate3(timestamp) {
  let date 
  if(!timestamp){
    date = new Date()
  } else{
    date = new Date(timestamp)
  }
  let year = date.getFullYear(); // 获取完整的年份，比如 2021
  let month = date.getMonth() + 1; // 获取月份，注意月份范围是 0-11，所以需要 +1
  if (month < 10) {
    month = "0" + month;
  }
  let day = date.getDate(); // 获取日期，比如 27
  if (day < 10) {
    day = "0" + day;
  }
  // 拼接成标准时间格式的字符串
  return `${year}/${month}/${day}`;
}


// 时间格式转换，精确到秒数
export function formatDate4(timestamp) {
  if (!timestamp) return "";
  let date = new Date(timestamp); // 使用 Date 类将时间戳转换为日期对象
  let year = date.getFullYear(); // 获取完整的年份，比如 2021
  let month = date.getMonth() + 1; // 获取月份，注意月份范围是 0-11，所以需要 +1
  if (month < 10) {
    month = "0" + month;
  }
  let day = date.getDate(); // 获取日期，比如 27
  if (day < 10) {
    day = "0" + day;
  }
  let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); // 获取小时，比如 9
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); // 获取分钟，比如 12
  let seconds =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // 获取秒数，比如 24
  // 拼接成标准时间格式的字符串
  return `${month}月${day}日 ${hours}:${minutes}`;
}


export function getTodayWeekDayName(timestamp) {
  const today = new Date(timestamp);
  const weekDayNumber = today.getDay();
  const weekDays = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
  return weekDays[weekDayNumber];
}

// 获取当前时间下一个月最后一天
export function getLastDayOfNextMonth() {
  let date = new Date(); // 使用 Date 类将时间戳转换为日期对象
  let year = date.getFullYear(); // 获取完整的年份，比如 2021
  let month = date.getMonth() + 1; // 获取月份，注意月份范围是 0-11，所以需要 +1
  // JavaScript中的月份是从0开始的，所以实际设置的月份应该是提供的月份+1
  var dateTime = new Date(year, month + 1, 0);
  let m = dateTime.getMonth() + 1;
  if (m < 10) {
    m = "0" + m;
  }
  let d = dateTime.getDate(); // 获取日期，比如 27
  if (d < 10) {
    d = "0" + d;
  }
  // 返回日期对象的数组表示，格式为[年,月,日]
  return dateTime.getFullYear() + "/" + m + "/" + d;
}

// 获取当前时间三个月后
export function getLastDayOfThreeMonth() {
  let date = new Date(); // 使用 Date 类将时间戳转换为日期对象
  let year = date.getFullYear(); // 获取完整的年份，比如 2021
  let month = date.getMonth() + 1; // 获取月份，注意月份范围是 0-11，所以需要 +1
  // JavaScript中的月份是从0开始的，所以实际设置的月份应该是提供的月份+1
  var dateTime = new Date(year, month + 3, 0);
  let m = dateTime.getMonth() + 1;
  if (m < 10) {
    m = "0" + m;
  }
  let d = dateTime.getDate(); // 获取日期，比如 27
  if (d < 10) {
    d = "0" + d;
  }
  // 返回日期对象的数组表示，格式为[年,月,日]
  return dateTime.getFullYear() + "/" + m + "/" + d;
}

//根据字典类型获取字典值
export function getDictTypeFun(key) {
  return new Promise((resolve) => {
    SystemApi.getValueByDicKey(key).then((res) => {
      if (res.code === 0) {
        resolve(res.data);
      }
    });
  });
}


export function isWebView() {
  let isWeb = false
  console.log( navigator.userAgent.indexOf("miniProgram"), '896');
  wx.miniProgram.getEnv((res)=> {
    console.log( res , '895');
    if(res.miniprogram){
      isWeb = true
    }
  })
  if(navigator.userAgent.indexOf("miniProgram") > -1){
    isWeb = true
  }
  return isWeb
}