// 是否开启访问模式
export const openVisitorMode = uni.getStorageSync('openVisitorMode')

const baseWhitePage = [
    '/pages/author/transit',
    '/pages/author/index',
    '/MP_verify_TdX7yqmdNNoP1lK4.txt',
    '/pages/public/error',
    '/pages/YunfuHelpNew/home',
    '/pages/YunfuHelpNew/helpLibrary',
    '/pages/goods/index',
    '/pages/index/festivals',
]

const whitePage = [
    ...baseWhitePage,
    '/pages/index/index',
    '/pages/index/user',
    // '/pages/index/category',
    '/pages/goods/list',
    '/pages/cakeModule/CakeHome',
    '/pages/YunfuHelp/home',
    '/pages/movie/index',
    '/pages/equityModule/equityHome',
    '/pages/index/discount',
    '/pages/index/promotion',
]


export function GetWhitePages() {
    return openVisitorMode ? whitePage : baseWhitePage;
}


export function IsWhitePage() {
    const path = window.location.href
    let isWhitePage = false;
    if (!path.includes('/pages')) {
        // 首页
        if (openVisitorMode) isWhitePage = true;
        else {
            if (uni.getStorageSync('token')) isWhitePage = true;
            else isWhitePage = false;
        }
    }
    else {
        const arr = GetWhitePages();
        arr.map(item => {
            if (path.includes(item)) {
                isWhitePage = true;
            }
        })
    }

    return isWhitePage;
}